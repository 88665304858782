import { Component, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfContent from "@/vue/domain/content/df-content";
import moment from "moment";
import DfStore from "@/vue/domain/store/df-store";
import carousel from "vue-owl-carousel2";

const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfStoreCard: DfStoreCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
    DfSectionAttachments: () => Utils.externalComponent2("df-section-attachments"),
    carousel
  },
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfPagePageComponent extends Vue {
  private selectedCarouselThumb = 0;

  get pages(): Array<DfContent> {
    const storePages: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "PAG");
    const brandPages: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "PAG");
    const news: Array<DfContent> = [...storePages, ...brandPages].sort((contentA, contentB) => (contentA.priority <= contentB.priority ? 1 : -1));
    return news;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get page(): DfContent {
    return this.pages.find((page: DfContent) => page.alias === this.$route.params.pageAlias);
  }

  get pagePreview(): string {
    return Utils.getPropertyImageUrl(this.page, Utils.PROPERTY_PREVIEW, null);
  }

  get pageTitle(): string {
    return Utils.getPropertyValue(this.page, Utils.PROPERTY_TITLE, "STRING");
  }

  get pageBody(): string {
    return Utils.getPropertyValue(this.page, Utils.PROPERTY_BODY, "TEXT");
  }

  get pageDate(): string {
    return Utils.getPropertyValue(this.page, Utils.PROPERTY_DATE, "DATE");
  }

  get pageAttachments(): Array<any> {
    return Utils.getPropertyValues(this.page, Utils.PROPERTY_ATTACHMENTS, "FILE");
  }

  get medias(): Array<any> {
    return this.pageAttachments.filter((attachment) => attachment.image);
  }

  get files(): Array<any> {
    return this.pageAttachments.filter((attachment) => !attachment.image);
  }

  get showBreadcrumb(): boolean {
    return Utils.showBreadcrumb();
  }

  private goBack() {
    Utils.goBack(this);
  }

  private previewUrl(image: any): string {
    return `${Utils.getContextPath(true)}/files/${image.uniqueId}/${image.basename}.${image.extension}`;
  }

  private carouselChange(event: any) {
    this.selectedCarouselThumb = event.item.index % event.item.count;
    Array.from(this.$el.getElementsByTagName("video")).forEach(function (video: any) {
      if (!video.paused) {
        video.pause();
      }
    });
  }
}
